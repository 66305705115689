import React, { useState } from 'react'
import parse from 'html-react-parser'
import axios from 'axios'
import classNames from 'classnames'
import * as styles from './style.module.scss'

export const ContactForm = ({ data }) => {
  const [success, setSuccess] = useState(null)

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  })

  // console.log('server state: ', serverState)

  const { email, intro, message, name, privacy, send, subject, title } = data

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      setSuccess(true)
      // form.reset()
    }
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    setServerState({ submitting: true })
    axios({
      method: "post",
      url: "https://getform.io/f/bde6c421-e54c-4f90-a7c4-2230ab491fe7",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }

  return (
    <form
      onSubmit={handleOnSubmit}
      className={styles.ContactForm}
      encType="multipart/form-data"
    >
      <div className={classNames(styles.formContent, {[styles.success]: success})}>
        <div className={styles.title}>
          {parse(title.html)}
        </div>
        <div className={styles.intro}>
          {parse(intro.html)}
        </div>
        <div className={styles.inputGroup}>
          <div className={styles.input}>
            <input
              className={styles.inputText}
              name="Name"
              placeholder={name.text}
              required
              type="text"
            />
          </div>
        </div>
        <div className={styles.inputGroup}>
          <div className={styles.input}>
            <input
              className={styles.inputText}
              name="Email"
              placeholder={email.text}
              required
              type="email"
            />
          </div>
          <div className={styles.input}>
            <input
              className={styles.inputText}
              name="Subject"
              placeholder={subject.text}
              type="text"
            />
          </div>
        </div>
        <div className={classNames(styles.textArea, styles.input)}>
          <textarea
            className={styles.inputText}
            placeholder={message.text}
            name="Message"
          />
        </div>
        <div className={styles.lastRow}>
          <div data-html className={styles.privacy}>
            <input type="checkbox" name="privacy" id="privacy" required />
            <label htmlFor="privacy">{parse(privacy.html)}</label>
          </div>
          <button className={styles.submitButton} type="submit">
            {send.text}
          </button>
        </div>
      </div>
      <div className={classNames(styles.loading, {[styles.active]: serverState.submitting})}>
        <p>Loading …</p>
      </div>
      <div className={classNames(styles.successMessage, {[styles.active]: success})}>
        <p>Thank you for your message!<br/> We will be in touch soon</p>
      </div>
    </form>
  )
}
